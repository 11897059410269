import { isNumLike, isString, toNum } from '../types';
const StorageSizeUnits = ['B', 'KiB', 'MiB', 'GiB', 'TiB', ''];
const StorageSizeMap = {
    kib: 1024,
    mib: 1024 * 1024,
    gib: 1024 * 1024 * 1024,
    tib: 1024 * 1024 * 1024 * 1024,
};
export const bytesToMb = (value) => (value / StorageSizeMap.mib);
export const mbToBytes = (value) => (value * StorageSizeMap.mib);
export const gbToBytes = (value) => (value * StorageSizeMap.gib);
export const parseBytes = (value) => {
    if (isNumLike(value)) {
        const size = toNum(value);
        const unit = isString(value) && value.replace(size, '').trim().toLowerCase();
        if (unit && StorageSizeMap[unit]) {
            return size * StorageSizeMap[unit];
        }
        return Math.max(0, size);
    }
};
export const formatBytes = (x) => {
    let l = 0, n = parseInt(x.toString(), 10) || 0;
    while (n >= 1024 && ++l) {
        n = n / 1024;
    }
    return (n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + StorageSizeUnits[l]);
};
