import { isFunction } from './functions';
import { _getObjectTag, Prototype } from './prototype';
export const isUndefined = (v) => (typeof v === 'undefined' || v === undefined);
export const isNull = (v) => v === null;
export const isBool = (v) => typeof v === 'boolean';
export const boolToObject = (v, fallback = {}) => {
    if (v) {
        if (typeof v !== 'boolean')
            return v;
        return fallback;
    }
};
export const isInteger = (obj) => String(Math.floor(Number(obj))) === obj;
export const isNum = (v) => typeof v === 'number' && !isNaN(v);
export const isNumLike = (v) => isNum(parseFloat(v));
export const toNum = (v) => isNum(v = parseFloat(v)) ? v : 0;
export const isString = (v) => typeof v === 'string' || Object.prototype.toString.call(v) === '[object String]';
export const toString = (v) => isString(v) ? v : '';
export const isNumOrStr = (v) => isString(v) || isNumLike(v);
export const isScalar = (v) => isString(v) || isNum(v) || isBool(v);
export const toScalar = (v) => typeof v === 'object' ? v.toString() : v;
export function cleanIds(ids) {
    return [ids].flat(2).map(toNum).filter(id => id > 0);
}
export function mapIds(ids, updater) {
    return cleanIds(ids).map(id => {
        if (isFunction(updater)) {
            return updater(id);
        }
        return { ...updater, id };
    });
}
export function inObject(val, key) {
    return (isObject(val) && key in val);
}
export function isObjectLike(val) {
    return val !== null && typeof val === 'object';
}
export function isObject(val) {
    return isObjectLike(val) && !Array.isArray(val);
}
export function isNotEmptyObject(val) {
    return isObject(val) && Object.keys(val).length > 0;
}
export function isPlainObject2(val) {
    return isObjectLike(val) && val.constructor === Object;
}
export function isPlainObject(val) {
    if (!isObjectLike(val) || _getObjectTag(val) !== '[object Object]')
        return false;
    const proto = Prototype.get(val);
    return proto === null || proto === Prototype.getRoot(val);
}
export function toObject(val) {
    return isObject(val) ? val : {};
}
export function toPlainObject(val) {
    val = Object(val);
    const result = {};
    for (const key in val) {
        result[key] = val[key];
    }
    return result;
}
export function Readonly(obj) { return obj; }
export function forEachObject(obj, cb) {
    objectKeys(obj).forEach((key => cb(key, obj[key], obj)));
}
export function isKeyOf(obj, k) {
    return k in obj;
}
export function removeKeyOf(obj, k) {
    obj[k] = null;
    delete obj[k];
    return obj;
}
export function objectKeys(obj) {
    return Object.keys(obj);
}
export function pick(obj, keys) {
    const entries = pickKeys(obj, keys).map(key => [key, obj[key]]);
    return Object.fromEntries(entries);
}
export function pickKeys(obj, keys) {
    return (keys ? [keys].flat(4).filter((key) => isKeyOf(obj, key)) : objectKeys(obj));
}
export function omit(obj, keys) {
    return pick(obj, omitKeys(obj, keys));
}
export function omitKeys(obj, keys) {
    const ignoredKeys = [keys].flat(4);
    return objectKeys(obj).filter((k) => !ignoredKeys.includes(k));
}
export function filterKeys(obj, cb) {
    return objectKeys(obj).flatMap((key) => (cb(key, obj[key], obj) || []));
}
export function filterObject(obj, cb) {
    const entries = filterKeys(obj, cb).map(key => [key, obj[key]]);
    return Object.fromEntries(entries);
}
export function mapObject(obj, cb) {
    return objectKeys(obj)
        .reduce((res, key) => {
        const value = cb(key, obj[key], obj);
        return value === undefined ? res : [...res, value];
    }, []);
}
export function mapObjectEntries(obj, cb) {
    return objectKeys(obj)
        .reduce((result, key) => {
        const value = cb(key, obj[key], obj);
        return value === undefined
            ? { ...result }
            : { ...result, [key]: value };
    }, {});
}
export function reduceObject(obj, cb, initialValue = Object.create(null)) {
    return objectKeys(obj)
        .reduce((result, key) => {
        const value = cb(key, obj[key], obj);
        return value === undefined
            ? { ...result }
            : { ...result, ...value };
    }, initialValue);
}
export function mapObjectKeys(obj, cb) {
    const newObj = {};
    for (const [k, v] of Object.entries(obj)) {
        newObj[k] = cb(v, k, obj);
    }
    return newObj;
}
export function cleanObj(obj) {
    const newObj = {};
    if (isObject(obj)) {
        Object.keys(obj).forEach(key => {
            if (obj[key] !== undefined) {
                newObj[key] = obj[key];
            }
        });
    }
    return newObj;
}
export function match(key, matches) {
    return matches[key];
}
export function taggedObject(obj, key) {
    const keys = objectKeys(obj);
    return keys.reduce((collection, k) => {
        const inner = obj[k];
        collection[k] = {
            [key]: k,
            ...inner,
        };
        return collection;
    }, {});
}
export function objectOmit(obj, keys) {
    const newObj = { ...obj };
    objKeys(keys).forEach(key => {
        if (key in newObj)
            delete newObj[key];
    });
    return newObj;
}
export function objectPick(obj, keys) {
    const newObj = {};
    objKeys(keys).forEach(key => {
        newObj[key] = obj[key];
    });
    return newObj;
}
const objKeys = (keys) => (Array.isArray(keys) ? keys.flat(3) : Object.keys(keys));
